$base-body-font-family: $font-family;
$base-body-font-weight: $font-weight-regular;
$base-body-font-size: $font-size;
$global-font-family: $font-family;

$section-padding-vertical: 24px;
$section-padding-vertical-m: 48px;


$section-large-padding-vertical: 48px;
$section-large-padding-vertical-m: 80px;

$container-large-max-width: 1760px;
$container-padding-horizontal: 16px;
$container-padding-horizontal-m: 48px;

$grid-gutter-horizontal: 24px;
$grid-gutter-horizontal-l: 32px;
$grid-gutter-vertical: 32px;

$margin-small-margin: 8px;
$margin-margin: 16px;
$margin-medium-margin: 24px;
$margin-large-margin: 32px;
$margin-large-margin-l: 32px;
$margin-xlarge-margin: 48px;
$margin-xlarge-margin-l: 48px;

@mixin hook-button-primary {
  border-radius: 100px;
  background-color: #fff;
  font-weight: $font-weight-bold;
  line-height: 20px;
  padding: 14px 32px;
  box-shadow: 0px 8px 24px rgba(255, 255, 255, 0.25);
  transition: all .3s ease;
}

@mixin hook-button-primary-hover {
  color: $default-color-first !important;
  background-color: $primary-color-second;
  box-shadow: 0px 8px 24px rgba(3, 169, 244, 0.25);
  transform: translateY(-4px);
}

@mixin hook-button-primary-active {
  transform: translateY(0);
}

@mixin hook-subnav-misc {
  .uk-subnav {
    .head &,
    #mobile-menu & {
      margin: 0;
      >* > :first-child {
        color: $default-color-second;
        transition: all .3s ease;
        font-weight: $font-weight-medium;
      }
      >li {
        padding: 0;
      }
      >li:not(:last-child) {
        margin-right: 48px;
        @media screen and (max-width: 1600px) {
          margin-right: 32px;
        }
        @media screen and (max-width: 1200px) {
          margin-right: 24px;
        }
        @media screen and (max-width: 960px) {
          margin-right: 16px;
        }
      }
      >li>a:hover {
        color: $primary-color-first;
        fill: $primary-color-first;
      }
      >li>a:active {
        transform: scale(0.9);
      }
    }
    .head.index-head & {
      >* > :first-child {
        color: $default-color-first;
        transition: all .3s ease;
      }
      >li>a:hover {
        color: $primary-color-first;
        fill: $primary-color-first;
      }
      >li>a:active {
        transform: scale(0.9);
      }
    }
    #mobile-menu .mobile-navigation & {
      display: flex;
      flex-direction: column;
      >li {
        margin: 0;
        &.uk-active {
          > a {
            color: $primary-color-first;
          }
        }
        > a {
          font-size: 20px;
          margin-bottom: 16px;
          text-align: center;
        }
      }
    }
    #mobile-menu .mobile-contacts & {
      flex-direction: column;
      li {
        margin: 0;
        >a {
          font-size: 20px;
          margin-bottom: 16px;
        }
      }
    }
    .head & {
      >li.uk-active > a {
        color: $primary-color-first;
      }
    }
  }
}

@mixin hook-dotnav {
  >li>a {
    background-color: #a0a0a0;
    width: 12px;
    height: 12px;
  }
  >li.uk-active>a {
    background-color: $primary-color-first;
    width: 16px;
    height: 16px;
  }
  >li:first-child {
    padding-left: 0;
  }
}

@mixin hook-offcanvas-misc {
  .uk-offcanvas {
    right: 0;
  }
  .uk-offcanvas-bar {
    width: 100%;
    padding: 24px 16px;
    background-color: $default-color-first;
    @media screen and (min-width: 639px) {
      padding: 24px 30px;
    }
    @media screen and (min-width: 959px) {
      padding: 48px;
    }
  }
}