@for $i from 1 through 6 {
  @keyframes preload-show-#{$i}{
    from{
      transform: rotateZ(60* $i + deg) rotateY(-90deg) rotateX(0deg);
      border-left-color: darken($primary-color-second, 20%);
    }
  }
  @keyframes preload-hide-#{$i}{
    to{
      transform: rotateZ(60* $i + deg) rotateY(-90deg) rotateX(0deg);
      border-left-color: darken($primary-color-second, 20%);
    }
  }

  @keyframes preload-cycle-#{$i}{

    $startIndex: $i*5;
    $reverseIndex: (80 - $i*5);

    #{$startIndex * 1%}{
      transform: rotateZ(60* $i + deg) rotateY(90deg) rotateX(0deg);
      border-left-color: darken($primary-color-second, 20%);
    }
    #{$startIndex + 5%},
    #{$reverseIndex * 1%}{
      transform: rotateZ(60* $i + deg) rotateY(0) rotateX(0deg);
      border-left-color: $primary-color-second;
    }

    #{$reverseIndex + 5%},
    100%{
      transform: rotateZ(60* $i + deg) rotateY(90deg) rotateX(0deg);
      border-left-color: darken($primary-color-second, 20%);
    }
  }
}

@keyframes preload-flip{
  0%{
    transform: rotateY(0deg) rotateZ(-60deg);
  }
  100%{
    transform: rotateY(360deg) rotateZ(-60deg);
  }
}

.preloader-wrapper {
  position: fixed;
  background-color: #fff;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.preloader{
  position: relative;
  top: 50%;
  left: 50%;
  font-size: 20px;
  display: block;
  width: 3.75em;
  height: 4.25em;
  margin-left: -1.875em;
  margin-top: -2.125em;
  transform-origin: center center;
  transform: rotateY(180deg) rotateZ(-60deg);

  .slice{
    border-top: 1.125em solid transparent;
    border-right: none;
    border-bottom: 1em solid transparent;
    border-left: 1.875em solid $primary-color-second;
    position: absolute;
    top: 0px;
    left: 50%;
    transform-origin: left bottom;
    border-radius: 3px 3px 0 0;
  }

  @for $i from 1 through 6 {
    .slice:nth-child(#{$i}) {
      transform: rotateZ(60* $i + deg) rotateY(0deg) rotateX(0);
      animation: .15s linear .9 - $i*.08s preload-hide-#{$i} both 1;
    }
  }


  &.loading{
    animation: 2s preload-flip steps(2) infinite both;
    @for $i from 1 through 6 {
      .slice:nth-child(#{$i}) {
        transform: rotateZ(60* $i + deg) rotateY(90deg) rotateX(0);
        animation: 2s preload-cycle-#{$i} linear infinite both;
      }
    }
  }
}

html {
  color: $default-color-second;
  background: url("../img/body.svg") repeat 50% 50%;
  @media screen and (max-width: 640px) {
    background-size: contain;
  }
}

.head {
  padding-top: 48px;
  @media screen and (max-width: 960px) {
    padding-top: 24px;
  }
}

.logo {
  color: $default-color-second;
  transition: all .3s ease;
  &:hover {
    color: $primary-color-first;
  }
}

.head.index-head .logo {
  color: $default-color-first;
  transition: all .3s ease;
  svg {
    transition: all .3s ease;
  }
  &:hover {
    color: $primary-color-first;
    svg {
      color: $primary-color-first;
      fill: $primary-color-first;
    }
  }
}

.service-card {
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding-left: 16px;
  padding-right: 16px;
  @media screen and (max-width: 1200px) {
    max-width: 500px;
  }
  &-title {
    padding-top: 32px;
    padding-bottom: 24px;
    color: $default-color-first;
    font-size: 24px;
    font-weight: $font-weight-medium;
    p {
      margin: 0;
    }
  }
  &-desc {
    color: $default-color-first;
    margin-bottom: 32px;
    p {
      margin: 0;
    }
    ul {
      list-style: none;
      padding: 0;
    }
  }
}

.inspiration,
.event-recommends-title,
.clients-title {
  font-size: 42px;
  font-weight: $font-weight-medium;
  padding-top: 80px;
  @media screen and (max-width: 960px) {
    padding-top: 48px;
    text-align: center;
  }
  @media screen and (max-width: 640px) {
    padding: 0;
    text-align: left;
    line-height: 1;
    font-size: 32px;
  }
}



.section-contacts,
.section-events {
  @media screen and (max-width: 960px) {
    text-align: center;
  }
  @media screen and (max-width: 640px) {
    text-align: left;
  }
  span {
    color: #767676;
  }
  p, a {
    color: $default-color-second;
    margin: 0;
    font-size: 24px;
    transition: all .3s ease;
  }
  a:hover {
    color: $primary-color-first;
    text-decoration: none;
  }
}

.footer {
  border-top: 1px solid #e0e0e0;
  p {
    margin: 0;
    a {
      color: $primary-color-second;
      font-weight: $font-weight-bold;
      transition: all .3s ease;
      &:hover {
        color: $primary-color-first;
        text-decoration: none;
      }
    }
  }
}

.navigation {
  @media screen and (max-width: 1100px) {
    display: none;
  }
}

.hamb-menu {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  @media screen and (min-width: 1100px) {
    display: none;
  }
  &:hover {
    color: $primary-color-first;
    fill: $primary-color-first;
  }
}

.head.index-head {
  position: absolute;
  width: 100%;
  svg {
    color: #fff;
    fill: #fff;
  }
}

.main_banner {
  height: 80vh;
  background: url("../img/main_banner.svg") no-repeat 0% 50%;
  background-size: cover;
  min-height: 900px;
  color: $default-color-first;
  @media screen and (max-width: 959px) {
    min-height: 570px;
  }
  &_title {
    font-size: 60px;
    font-weight: $font-weight-medium;
    @media screen and (max-width: 959px) {
      font-size: 42px;
    }
    @media screen and (max-width: 639px) {
      font-size: 32px;
    }
  }
}

.index-services {
  position: relative;
  width: 100%;
  top: -225px;
  max-height: 280px;
  @media screen and (max-width: 1199px) {
    max-height: 700px;
  }
  @media screen and (max-width: 959px) {
    max-height: 1150px;
    top: -120px;
  }
}

.video-card {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .6);
  }
  &.height-video {
    @media screen and (min-width: 1599px) {
      min-height: 800px;
      #player {
        min-height: 800px;
      }
    }
  }
  &.event-page-video {
    .video-play {
      color: #4CB2B4;
      fill: #4CB2B4;
    }
  }
}
#player {
  position: relative;
  z-index: -1;
  opacity: 0;
  min-height: 588px;
  @media screen and (max-width: 639px) {
    min-height: 250px;
  }
}

.video-play {
  position: absolute;
  top: calc(50% - 57px);
  left: calc(50% - 75px);
  color: $primary-color-second;
  fill: $primary-color-second;
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: $font-weight-medium;
  padding: 0;
  outline: none;
  cursor: pointer;
  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
  svg {
    transition: all .3s ease;
    width: 80px;
    margin-bottom: 16px;
  }
}

.main-about-title {
  font-size: 42px;
  font-weight:  $font-weight-medium;
  @media screen and (max-width: 639px) {
    font-size: 32px;
  }
}

.main-about-anchors {
  display: flex;
  @media screen and (max-width: 639px) {
    flex-direction: column;
  }
  a {
    display: flex;
    align-items: center;
    font-weight: $font-weight-bold;
    color: $primary-color-second;
    fill: $primary-color-second;
    transition: all .3s ease;
    span {
      margin-left: 8px;
    }
    &:not(:last-child) {
      margin-right: 48px;
      @media screen and (max-width: 639px) {
        margin: 0;
        margin-bottom: 16px;
      }
    }
    &:hover {
      color: $primary-color-first;
      text-decoration: none;
    }
    &:active {
      transform: scale(0.9);
    }
  }
}

.section-title,
.article-title {
  font-size: 42px;
  font-weight: $font-weight-medium;
  @media screen and (max-width: 959px) {
    padding-top: 48px;
    text-align: center;
  }
  @media screen and (max-width: 639px) {
    text-align: left;
    font-size: 32px;
  }
}

.article-title {
  color: #81C784;
}


.section-map {
  height: 520px;
  border-radius: 16px;
  overflow: hidden;
  @media screen and (max-width: 959px) {
    height: 280px;
  }
}

.footer.contact-page {
  border: none;
}

.img-card {
  img {
    width: 100%;
    object-fit: cover;
    border-radius: 24px;
    //max-height: 590px;
  }
}

.about-page-section {
  margin-bottom: 24px;
}

.portfolio-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $default-color-second;
  transition: all .3s ease;
  &:hover {
    text-decoration: none;
    color: $primary-color-second;
    .portfolio-card-img::before {
      left: -150%;
    }
    .portfolio-card-img::after {
      opacity: 0.5;
    }
  }
}

.portfolio-card-img {
  border-radius: 24px;
  overflow: hidden;
  margin-bottom: 16px;
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 150%;
    width: 100%;
    height: 100%;
    background-color: $primary-color-second;
    opacity: 0.5;
    transform: skew(45deg);
    transition: 0.6s ease-in-out;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $primary-color-second;
    opacity: 0;
    transition: 0.6s ease-in-out;
  }
  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
  @media screen and (max-width: 959px) {
    height: 280px;
  }
}

.portfolio-card-title {
  text-align: center;
  font-size: 20px;
  max-width: 375px;
  font-weight: $font-weight-medium;
  @media screen and (max-width: 959px) {
    font-size: 18px;
    max-width: 280px;
  }
}

.section-pagination {
  padding-bottom: 48px;
  .pagination {
    padding: 0 10px;
    margin: 0;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 20px;
    @media (max-width: 640px) {
      font-size: 16px;
    }
    li {
      display: inline-block;
      margin: 0 8px;
      cursor: pointer;
      color: #848e99;
      transition: all 0.3s ease;
      &.pagination-active a {

      }
      a {
        color: $default-color-second;
        position: relative;
        transition: all 0.3s ease;
      }
      a:hover {
        color: $primary-color-second;
        text-decoration: none;
      }
    }
  }
}

.pagination-button:hover {
  text-decoration: none;
  color: $primary-color-second;
  fill: $primary-color-second;
}

.pagination-button {
  transition: all 0.3s ease;
  position: relative;
  display: block;
  cursor: pointer;
  color: $default-color-second;
  fill: $default-color-second;
}

.section-article-desc {
  margin-top: 16px;
  ul {
    padding: 0;
    margin: 0;
    margin-bottom: 24px;
    li {
      list-style-type: none;
      font-size: 20px;
    }
  }
  p {
    margin-bottom: 0;
  }
}

.event-gallery {
>div a{
  display: block;
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 150%;
    width: 100%;
    height: 100%;
    background-color: $primary-color-second;
    opacity: 0.5;
    transform: skew(45deg);
    transition: 0.6s ease-in-out;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $primary-color-second;
    opacity: 0;
    transition: 0.6s ease-in-out;
  }
  &:hover {
    text-decoration: none;
    color: $primary-color-second;
    &::before {
      left: -150%;
    }
    &::after {
      opacity: 0.5;
    }
  }
  img {
    width: 100%;
    object-fit: cover;
  }
}
}

.h1-1 {
  height: 100%;
}

.clients-slider {
  img {
    width: 100%;
    object-fit: cover;
  }
}

.slider-nav a {
  width: 48px;
  height: 48px;
  color: $default-color-second;
  transition: all .3s ease;
  @media screen and (max-width: 639px) {
    width: 32px;
    height: 32px;
  }
  &:hover {
    color: $primary-color-first;
  }
}

#mobile-menu {
  .hamb-menu {
    color: $primary-color-first;
    fill: $primary-color-first;
  }
}