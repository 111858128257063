@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,700');

$default-color-first: #ffffff;
$default-color-second: #262626;

$primary-color-first: #FFB74D;
$primary-color-second: #03A9F4;

$font-family: 'Raleway', sans-serif;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-size: 16px;